<template>
    <div class="container">
        <qy-top-nav title="账单记录" />
        <van-dropdown-menu>
            <van-dropdown-item v-model="operType" :options="operTypeList"  @change="getOperType"/>
            <van-dropdown-item title="筛选" ref="item">
                <van-cell center title="开始时间" :value="beginTime" is-link @click="show = true,select = '1'"/>
                <van-cell center title="结束时间" :value="endTime" is-link @click="show = true,select = '2'"/>
                <van-calendar v-model="show" @confirm="onConfirm" :min-date="minDate" :max-date="maxDate"/>                <br>

                <div style="text-align: center;padding: 5px;">
                    <van-button type="info"   size="normal" @click="queryTime" style="border-radius: 5px;"><van-icon name="success" /> 确认</van-button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <van-button type="danger" size="normal" @click="close"   style="border-radius: 5px;"><van-icon name="cross" /> 清除</van-button>
                </div>
            </van-dropdown-item>
        </van-dropdown-menu>

        <van-cell-group>
            <van-list
                v-model="loading"
                :finished="finished"
                :offset="offset"
                finished-text="没有更多了"
                @load="onLoad"
            >
                    <van-cell :title="data.summary" :value="data.amount" :label="data.createTime.substring(5)" v-for="data in dataList"/>
            </van-list>
        </van-cell-group>
    </div>
</template>

<script>
    import request from "@/utils/request";
    export default {
        name: "balanceLog",
        data(){
            return {
                loading:false,
                finished:false,
                operType: "",
                minDate:"",
                maxDate:new Date(),
                operTypeList: [
                    { text: "全部", value: "" },
                    { text: "充值", value: "101" },
                    { text: "提现", value: "102" }
                ],
                select:'',
                beginTime:'',
                endTime:'',
                show:false,
                dataList:[],
                pageSize: 10,
                pageNum: 1,
                offset:50,
            }
        },
        created(){
            this.minDate = new Date(1975,1,1);
            let currentDate = new Date();
            this.beginTime = this.formatDate(new Date(currentDate.getFullYear(),currentDate.getMonth(),1));
            this.endTime = this.formatDate(currentDate);
        },
        methods:{
            onLoad(){
                let isLogin = this.isLogin();
                if(!isLogin){
                    this.$router.push("/login");
                    return
                }
                if(!this.finished){
                    this.doRequetQuery();
                }
            },
            close(){
                this.beginTime = '';
                this.endTime = '';
            },
            getOperType(v){
                this.pageNum = 1;
                this.dataList = [];
                this.loading =false;
                this.doRequetQuery();
            },
            queryTime(){
                this.pageNum = 1;
                this.dataList = [];
                this.loading =false;
                this.doRequetQuery();
                this.$refs.item.toggle();
            },
            onConfirm(date) {
                this.show = false;
                switch (this.select) {
                    case '1':
                        this.beginTime = this.formatDate(date);
                        break;
                    case '2':
                        this.endTime = this.formatDate(date);
                        break;
                    default:
                        this.beginTime = '';
                        this.endTime = '';
                        break;
                }
            },
            doRequetQuery(){
                let that = this;
                const db = {
                    operType:that.operType,
                    beginTime:that.beginTime,
                    endTime:that.endTime,
                    pageNum:that.pageNum,
                    pageSize:that.pageSize,
                }
                request.post('/api/balance/balanceLog',db).then(res => {
                    if(res.success){
                        let data = res.data;
                        let hasNextPage = data.hasNextPage;
                        let list = data.list;
                        if (list.length > 0){
                            that.dataList = that.dataList.concat(list);
                        }
                        that.loading = false;
                        if(!hasNextPage){
                            that.finished = true;
                        }else {
                            that.finished = false;
                            that.pageNum += 1;
                        }
                    }else {
                        that.finished = true;
                    }
                });
            },
            formatDate(date, fmt) {
                date = date == undefined ? new Date() : date;
                date = typeof date == 'number' ? new Date(date) : date;
                fmt = fmt || 'yyyy-MM-dd';
                var obj =
                    {
                        'y': date.getFullYear(), // 年份，注意必须用getFullYear
                        'M': date.getMonth() + 1, // 月份，注意是从0-11
                        'd': date.getDate(), // 日期
                        'q': Math.floor((date.getMonth() + 3) / 3), // 季度
                        'w': date.getDay(), // 星期，注意是0-6
                        'H': date.getHours(), // 24小时制
                        'h': date.getHours() % 12 == 0 ? 12 : date.getHours() % 12, // 12小时制
                        'm': date.getMinutes(), // 分钟
                        's': date.getSeconds(), // 秒
                        'S': date.getMilliseconds() // 毫秒
                    };
                var week = ['天', '一', '二', '三', '四', '五', '六'];
                for(var i in obj)
                {
                    fmt = fmt.replace(new RegExp(i+'+', 'g'), function(m)
                    {
                        var val = obj[i] + '';
                        if(i == 'w') return (m.length > 2 ? '星期' : '周') + week[val];
                        for(var j = 0, len = val.length; j < m.length - len; j++) val = '0' + val;
                        return m.length == 1 ? val : val.substring(val.length - m.length);
                    });
                }
                return fmt;
            },
        }
    }
</script>

<style scoped>
    div.container{
        font-size: 15px;
        background-color: #fbfbfb;
    }
    div.oper-time>label{
        font-size: 16px;
    }
    .van-cell>.van-cell__title>span{
        font-size: 16px;
    }
    .van-cell>.van-cell__title>div{
        font-size: 15px;
    }
    .van-cell>.van-cell__value>span{
        font-size: 18px;
        font-weight: bold;
        color: rosybrown;
    }
</style>